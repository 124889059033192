import './incidentSection5.module.css';

import { ClockIcon, TrashIcon } from '@heroicons/react/outline';
import Button from 'components/atoms/Button';
import Divider from 'components/atoms/Divider';
import Heading from 'components/atoms/Heading';
import Input from 'components/atoms/Input';
import { IMultiSelectComboBoxOption } from 'components/atoms/MultiSelectComboBox/MultiSelectComboBox';
import Text from 'components/atoms/Text';
import TextareaInput from 'components/atoms/TextareaInput';
import ToggleButtonV2 from 'components/atoms/ToggleButtonV2';
import React, { useEffect, useState } from 'react';
import {
	useGetIncidentSection5Mutation,
	useUpdateIncidentSection5Mutation,
} from 'redux/api/vehicleIncidents';
import { useAppSelector } from 'redux/hooks';
import {
	IOption,
	ISection5ErrorStates,
	IVehicleIncidentSection5,
	section5PercentageRadioButtons,
	IQueryReportSection,
} from 'types/VehicleIncidents';
import { disableWriteAccess } from 'utils/disableWriteAccess';
import { removeSeconds } from 'utils/removeSeconds';
import ComboBox from 'components/atoms/ComboBox';
import {
	FaFilePdf,
	FaFileWord,
	FaFileCsv,
	FaFileExcel,
	FaFileImage,
	FaFileAlt,
	FaFileAudio,
	FaFileVideo,
	FaFilePowerpoint,
} from 'react-icons/fa';
import RadioV2 from '../../atoms/RadioV2';
import { FileUploader } from 'react-drag-drop-files';
import UploadIconNew from '../../../assets/icons/UploadIconNew';
import DefaultFileIcon from '../../../assets/icons/DefaultFileIcon';
import LoaderSpinner from '../../atoms/LoaderSpinner';
import convertSize from 'convert-size';
import axios, { AxiosProgressEvent } from 'axios';
import { TailSpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';

export interface IIncidentSection5 {
	sectionId: number | null;
	setSection: (section: IQueryReportSection) => void;
	setIncidentSection5: React.Dispatch<
		React.SetStateAction<IVehicleIncidentSection5>
	>;
	setSection5IncidentErrorStates: React.Dispatch<
		React.SetStateAction<ISection5ErrorStates>
	>;
	setICamIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
	handleValidate: () => void;
	savingIncident: boolean;
	setSavingIncident: (input: boolean) => void;
	readTabs: { name: string; isRead: boolean }[];
	setReadTabs: (input: { name: string; isRead: boolean }[]) => void;
}
interface FileResponse extends File {
	id: string;
	url: string;
	ext: string;
}

interface ISection5InitialState {
	id: number;
	section1Id: number | null;
	incidentReportId: number | null;
	percentage_of_shift_worked: string;
	shift_start_time: string;
	organisational_factors: string;
	task_environmental_factors: string;
	individual_factors: string;
	absent_failed_defense: string;
	investigator: string;
	users: IMultiSelectComboBoxOption[] | { id: string; label: string }[];
	attachments: FileResponse[] | File[];
}

const initialState: ISection5InitialState = {
	id: 0,
	section1Id: 0,
	incidentReportId: 0,
	percentage_of_shift_worked: '',
	shift_start_time: '',
	organisational_factors: '',
	task_environmental_factors: '',
	individual_factors: '',
	absent_failed_defense: '',
	investigator: '',
	users: [],
	attachments: [],
};

const FileIcon: React.FC<{ ext: string }> = ({ ext }) => {
	const fileType = ext.toLowerCase();

	switch (fileType) {
		case '.pdf':
			return <FaFilePdf size="1.5em" style={{ color: '#FAA431' }} />;
		case '.docx':
			return <FaFileWord size="1.5em" style={{ color: '#FAA431' }} />;
		case '.csv':
			return <FaFileCsv size="1.5em" style={{ color: '#FAA431' }} />;
		case '.xlsx':
			return <FaFileExcel size="1.5em" style={{ color: '#FAA431' }} />;
		case '.png':
		case '.jpg':
		case '.jpeg':
			return <FaFileImage size="1.5em" style={{ color: '#FAA431' }} />;
		case '.txt':
			return <FaFileAlt size="1.5em" style={{ color: '#FAA431' }} />;
		case '.mp3':
			return <FaFileAudio size="1.5em" style={{ color: '#FAA431' }} />;
		case '.mp4':
			return <FaFileVideo size="1.5em" style={{ color: '#FAA431' }} />;
		case '.pptx':
			return <FaFilePowerpoint size="1.5em" style={{ color: '#FAA431' }} />;
		default:
			return <DefaultFileIcon width="1.5em" height="1.5em" />;
	}
};
const IncidentSection5: React.FC<IIncidentSection5> = ({
	sectionId,
	setIncidentSection5,
	setSection,
	setSection5IncidentErrorStates,
	handleValidate,
	savingIncident,
	setSavingIncident,
	readTabs,
	setReadTabs,
	setICamIsVisible,
}) => {
	const [initialSetSection, setInitialSetSection] = useState<boolean>(false);
	const [changedItem, setChangedItem] = useState<string>('');
	const [isInvestigation, setIsInvestigation] = useState(true);
	const [section5, setSection5] = useState(initialState);
	const [uploadFileLoader, setUploadFileLoader] = useState(false);
	const [uploadProgress, setUploadProgress] = useState<number>(0);

	function isFileResponse(file: File | FileResponse): file is FileResponse {
		return 'id' in file && 'url' in file && 'ext' in file;
	}

	const [getIncidentSection5, { data, isSuccess, isLoading }] =
		useGetIncidentSection5Mutation();
	const [updateIncidentSection5, { isLoading: saving }] =
		useUpdateIncidentSection5Mutation();

	const incidentSection1Id = useAppSelector(
		(state) => state.vehicleIncident.incidentSection1Id
	);
	const incidentReportId = useAppSelector(
		(state) => state.vehicleIncident.incidentReportId
	);
	const incidentBranchId = useAppSelector(
		(state) => state.vehicleIncident.incidentBranchId
	);
	const userPermission = useAppSelector((state) => state.user.permissions);

	const disableIncidentWriteAccess = disableWriteAccess(
		'incident_level',
		userPermission,
		incidentBranchId
	);

	useEffect(() => {
		if (saving) {
			setSavingIncident(true);
		} else {
			setSavingIncident(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saving]);

	useEffect(() => {
		setIncidentSection5(() => {
			return {
				id: section5.id,
				section1Id: section5.section1Id,
				incidentReportId: section5.incidentReportId,
				percentage_of_shift_worked: section5.percentage_of_shift_worked,
				shift_start_time: section5.shift_start_time,
				organisational_factors: section5.organisational_factors,
				task_environmental_factors: section5.task_environmental_factors, //TODO
				individual_factors: section5.individual_factors,
				absent_failed_defense: section5.absent_failed_defense,
				investigator: section5.investigator,
				users: section5.users,
				attachments: section5.attachments,
				incident_investigation_required: isInvestigation,
			};
		});
	}, [section5, setIncidentSection5, isInvestigation]);

	useEffect(() => {
		if (
			data &&
			!(changedItem === 'absent_failed_defense') &&
			!(changedItem === 'individual_factors') &&
			!(changedItem === 'organisational_factors') &&
			!(changedItem === 'task_environmental_factors') &&
			initialSetSection
		) {
			handleOnBlur();
		} else if (
			data &&
			initialSetSection &&
			!(changedItem === 'absent_failed_defense') &&
			!(changedItem === 'individual_factors') &&
			!(changedItem === 'organisational_factors') &&
			!(changedItem === 'task_environmental_factors')
		) {
			setInitialSetSection(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [section5]);

	useEffect(() => {
		if (sectionId !== null) {
			getIncidentSection5(sectionId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const uploadFileWithAxios = async (
		formData: FormData,
		progressCallback: (progressEvent: AxiosProgressEvent) => void
	) => {
		return await axios.post(
			process.env.REACT_APP_ALTUS_API + '/upload',
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
				onUploadProgress: progressCallback,
			}
		);
	};

	const [attachments, setAttachments] = useState<File[]>([]);

	const handleFileUpload = async (files: any) => {
		if (files.length > 0 && !uploadFileLoader) {
			// for (const file of files) {
			const formData = new FormData();
			for (let i = 0; i < files.length; i++) {
				formData.append('files', files[i]);
			}
			try {
				setUploadFileLoader(true);
				setSavingIncident(true);
				const uploadResult = await uploadFileWithAxios(
					formData,
					async (progressEvent: AxiosProgressEvent) => {
						const percentCompleted = Math.round(
							// @ts-ignore
							(progressEvent.loaded * 100) / progressEvent.total
						);
						setUploadProgress(percentCompleted);
					}
				);

				if (uploadResult) {
					const newAttachments = attachments.concat(uploadResult.data);
					setAttachments(newAttachments);
					await updatePayload(newAttachments);
					setUploadFileLoader(false);
					setSavingIncident(false);
					setUploadProgress(0);
				}
			} catch (error) {
				console.error('File upload fail', error);
			}
		}
	};

	const handleDeleteFile = async (fileId: string | number) => {
		try {
			const updatedAttachments = attachments.filter(
				(attachment) => isFileResponse(attachment) && attachment.id !== fileId
			);
			setAttachments(updatedAttachments);
			updatePayload(updatedAttachments);
		} catch (error) {
			console.error('Delete file fail', error);
		}
	};

	const handleFileTooLarge = () => {
		toast.error(
			`Sorry, that file is too large! Please keep uploads under 200mb.`
		);
	};

	useEffect(() => {
		if (isSuccess && data) {
			const {
				percentage_of_shift_worked,
				incident_investigation_required,
				shift_start_time,
				organisational_factors,
				task_environmental_factors,
				individual_factors,
				absent_failed_defense,
				users,
				investigator,
				attachments,
			} = data.data;

			let selectedPercentageRadioButton = percentage_of_shift_worked;
			section5PercentageRadioButtons.forEach((item) => {
				if (item.title === percentage_of_shift_worked) {
					selectedPercentageRadioButton = item.id;
				}
			});

			const formatShiftStartTime = removeSeconds(shift_start_time);

			setSection5({
				...section5,
				percentage_of_shift_worked: selectedPercentageRadioButton,
				shift_start_time: formatShiftStartTime,
				organisational_factors: organisational_factors || '',
				task_environmental_factors: task_environmental_factors || '',
				individual_factors: individual_factors || '',
				absent_failed_defense: absent_failed_defense || '',
				users,
				investigator,
				attachments,
			});

			setIsInvestigation(incident_investigation_required);

			setSection5IncidentErrorStates({
				section_5_shift_start_time: !!shift_start_time,
				section_5_investigator: !!investigator,
			});
		}
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleSection5Update = (
		property: string,
		value: string | boolean | Date | IOption[],
		disableUpdateRequest?: boolean
	) => {
		setChangedItem(property);
		const newSection5 = section5;
		// @ts-ignore
		newSection5[property] = value;
		setSection5(newSection5);
	};

	const updatePayload = async (newAttachments?: FileResponse[] | File[]) => {
		const { investigator, shift_start_time } = section5;

		if (!sectionId) {
			return;
		}

		let percentageOfShiftWorked = section5.percentage_of_shift_worked;
		section5PercentageRadioButtons.forEach((item) => {
			if (item.id === section5.percentage_of_shift_worked) {
				percentageOfShiftWorked = item.title;
			}
		});

		const formatShiftStartTime =
			shift_start_time && shift_start_time.length < 6
				? `${shift_start_time}:00.000`
				: shift_start_time;

		const updatedAttachments = newAttachments ?? attachments;

		const payload: IVehicleIncidentSection5 = {
			id: sectionId,
			percentage_of_shift_worked: percentageOfShiftWorked,
			organisational_factors: section5.organisational_factors,
			task_environmental_factors: section5.task_environmental_factors,
			individual_factors: section5.individual_factors,
			absent_failed_defense: section5.absent_failed_defense,
			incident_investigation_required: isInvestigation,
			investigator,
			users: section5.users,
			shift_start_time: formatShiftStartTime,
			section1Id: incidentSection1Id,
			incidentReportId,
			attachments: updatedAttachments,
		};

		setICamIsVisible(isInvestigation);
		await updateIncidentSection5(payload);

		const validateInvestigator = () => {
			if (isInvestigation) {
				if (section5.investigator) {
					return true;
				} else {
					return false;
				}
			} else {
				return true;
			}
		};

		const validateShiftStartTime = () => {
			if (section5.shift_start_time) {
				return true;
			} else {
				return false;
			}
		};

		setSection5IncidentErrorStates({
			section_5_investigator: validateInvestigator(),
			section_5_shift_start_time: validateShiftStartTime(),
		});
	};

	useEffect(() => {
		if (section5.attachments && section5.attachments.length > 0) {
			setAttachments(section5.attachments);
		}
	}, [section5.attachments]);

	const handleOnBlur = async () => {
		if (disableIncidentWriteAccess) {
			return;
		}

		await updatePayload();

		setSection5IncidentErrorStates({
			section_5_shift_start_time: !!section5.shift_start_time,
			section_5_investigator: !!section5.investigator,
		});
	};

	const handleChangeAndBlur = (
		property: string,
		value: string | boolean | Date | IOption[]
	) => {
		handleSection5Update(property, value);
		handleOnBlur();
	};

	const stack2 = (
		<div className="rounded-xl border-[2px] border-dashed border-secondary-100 w-full h-[96px] py-[10px] flex justify-center">
			{uploadFileLoader ? (
				<div className="flex flex-col justify-center">
					<Heading type="h3" className="underline">
						Upload currently in progress...
					</Heading>
				</div>
			) : (
				<div className="flex flex-col justify-evenly">
					<div className="flex flex-row justify-center">
						<UploadIconNew />
					</div>
					<Heading type="h3" className="underline">
						Click to upload or drag and drop
					</Heading>
				</div>
			)}
		</div>
	);

	useEffect(() => {
		if (data && initialSetSection) {
			handleOnBlur();
		} else if (data) {
			setInitialSetSection(true);
			handleOnBlur();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInvestigation]);

	return (
		<>
			{isLoading || !data ? (
				<LoaderSpinner />
			) : (
				<div>
					<Heading type="h1" className="uppercase mb-[64px] mt-[20px]">
						Incident Investigation
					</Heading>
					<ToggleButtonV2
						toggle={isInvestigation}
						setToggle={setIsInvestigation}
						label="Incident Investigation Required"
						isDisabled={disableIncidentWriteAccess}
					/>
					{isInvestigation && (
						<div>
							<ComboBox
								label="Investigator"
								options={section5.users}
								isDisabled={disableIncidentWriteAccess}
								required={true}
								onChange={(value: string) =>
									handleChangeAndBlur('investigator', value)
								}
								selected={section5.investigator}
								className="mb-10 w-80"
							/>
						</div>
					)}

					<Divider
						className="my-[64px]"
						color="!border-t-[4px] border-orange-500"
					/>
					<div className="flex flex-col mb-[20px]">
						<Input
							type="time"
							onChange={(value: string) =>
								handleSection5Update('shift_start_time', value, true)
							}
							onBlur={handleOnBlur}
							value={section5.shift_start_time}
							className="w-80 mb-[24px]"
							label="Shift Start Time"
							Icon={ClockIcon}
							iconClassName="text-black"
							iconPosition="trailing"
							placeholder="Select"
							isDisabled={disableIncidentWriteAccess}
						/>
						<Text
							type="bold"
							className="block text-base font-bold font-inter text-secondary mb-3.5"
						>
							Portion of Shift Worked when Incident Occurred *
						</Text>
						<div className="gap-2 w-full">
							<RadioV2
								options={section5PercentageRadioButtons}
								onChange={(value: string) =>
									handleChangeAndBlur('percentage_of_shift_worked', value)
								}
								defaultId={section5.percentage_of_shift_worked || '1'}
								radioDirection="grid"
								classNameWrapper="mt-2 mb-2 text-sm"
								isDisabled={disableIncidentWriteAccess}
							/>
						</div>
					</div>
					<Divider
						className="my-[64px]"
						color="!border-t-[4px] border-orange-500"
					/>
					<Heading type="h2" className="uppercase">
						What factors contributed to the incident occuring?
					</Heading>
					<div>
						<Text
							type="bold"
							className="text-sm mt-[32px] text-secondary-900 mb-[12px]"
						>
							Organisational Factors
						</Text>
						<TextareaInput
							id="1"
							className="w-full"
							value={section5.organisational_factors}
							rows={10}
							cols={35}
							placeholder="The underlying factors in the organisation that influenced and
							produced the conditions affecting the performance in the workplace.
							Examples: No SOP for a particular task, inadequate training, staff not
							properly inducted, no process to ensure appropriate resources on the
							job, failure of fatigue management process etc."
							resize={true}
							onChange={(value: string) =>
								handleSection5Update('organisational_factors', value, true)
							}
							onBlur={handleOnBlur}
							isDisabled={disableIncidentWriteAccess}
						/>
					</div>
					<div>
						<Text
							type="bold"
							className="text-sm mt-[24px] text-secondary-900 mb-[12px]"
						>
							Environmental / Task factors
						</Text>
						<TextareaInput
							id="2"
							className="w-full"
							value={section5.task_environmental_factors}
							rows={10}
							cols={35}
							placeholder="The local workplace factors. The conditions, circumstances or objects
							in the workplace that directly influenced human and equipment
							performance at the time or immediately prior to an incident. Examples:
							Extreme temperatures, poor light, wet weather, bend in road affecting
							visibility, no shoulder for placement of signs etc."
							resize={true}
							onChange={(value: string) =>
								handleSection5Update('task_environmental_factors', value, true)
							}
							onBlur={handleOnBlur}
							isDisabled={disableIncidentWriteAccess}
						/>
					</div>
					<div>
						<Text
							type="bold"
							className="text-sm mt-[24px] text-secondary-900 mb-[12px]"
						>
							Individual / Team Factors
						</Text>
						<TextareaInput
							id="3"
							className="w-full"
							value={section5.individual_factors}
							rows={10}
							cols={35}
							placeholder="The actions, errors, or violations that led directly to the incident.
							Examples: Traffic Controller crossed live lane of traffic; Traffic
							Controller stopping traffic with no Stop Bat or not wearing PPE;
							Member of public on mobile phone driving through site; Machine
							operator not communicating plant movement etc."
							resize={true}
							onChange={(value: string) =>
								handleSection5Update('individual_factors', value, true)
							}
							onBlur={handleOnBlur}
							isDisabled={disableIncidentWriteAccess}
						/>
					</div>
					<div>
						<Text
							type="bold"
							className="text-sm mt-[24px] text-secondary-900 mb-[12px]"
						>
							Absent / Failed Defenses
						</Text>
						<TextareaInput
							id="4"
							className="w-full"
							value={section5.absent_failed_defense}
							rows={10}
							cols={35}
							placeholder="The last minute measures which did not prevent the outcome of the
							incident or reduce its consequences. Examples: Hand brake not engaged
							(runaway vehicle); Vehicle protruding into live lane (Vehicle
							incident); TC not wearing steel capped boots (foot injury) etc."
							resize={true}
							onChange={(value: string) =>
								handleSection5Update('absent_failed_defense', value, true)
							}
							onBlur={handleOnBlur}
							isDisabled={disableIncidentWriteAccess}
						/>
					</div>
					<Divider
						className="my-[64px]"
						color="!border-t-[4px] border-orange-500"
					/>
					<Heading type="h2" className="uppercase mb-8">
						Upload files
					</Heading>
					<div className="pt-[40px] pb-[30px] px-6 border-solid border-[1px] bg-[#FEEDD6] rounded-md min-w-[748px] w-1/2 justify-between">
						<div className="flex flex-col">
							{!uploadFileLoader && (
								<div className="mb-6">
									<FileUploader
										multiple={true}
										handleChange={handleFileUpload}
										name="file"
										children={stack2}
										maxSize={200}
										disabled={uploadFileLoader}
										onSizeError={handleFileTooLarge}
									/>
								</div>
							)}
							<div className="">
								<div className="flex flex-row justify-center">
									{uploadFileLoader ? (
										<div className="flex flex-col justify-center mb-6 mt-2 w-full">
											{uploadProgress === 100 ? (
												<div className="flex flex-row justify-left">
													<Text type="bold" className="pb-4 text-left mr-4">
														Processing...
													</Text>
													<TailSpin color="#FAA431" height={30} width={30} />
												</div>
											) : (
												<>
													<Text type="bold" className="pb-4 text-left">
														Uploading... {uploadProgress}%
													</Text>
													<progress
														value={uploadProgress}
														max="100"
														className="w-full"
													/>
												</>
											)}
										</div>
									) : null}
								</div>
								{attachments.length > 0 && (
									<Text type="bold" className="pb-4 text-left">
										Uploaded Files
									</Text>
								)}
								{attachments.map((attachment, index) =>
									isFileResponse(attachment) ? (
										<div
											key={attachment.id}
											className="flex mb-[16px] justify-between"
										>
											<div className="flex flex-row">
												<div className="flex flex-col justify-center">
													<FileIcon ext={attachment.ext} />
												</div>
												<div>
													<div
														className="text-base font-inter text-secondary-900 cursor-pointer flex-grow ml-4"
														onClick={() =>
															window.open(attachment.url, '_blank')
														}
													>
														{attachment.name}
													</div>
													<div className="text-[11px] text-secondary-300 ml-4">
														{convertSize(attachment.size * 1000, {
															accuracy: 2,
														})}
													</div>
												</div>
											</div>
											<div className="flex flex-col justify-center">
												<TrashIcon
													height={25}
													width={25}
													className={
														savingIncident || uploadFileLoader
															? 'ml-4 text-secondary-300'
															: 'cursor-pointer ml-4'
													}
													onClick={() =>
														savingIncident || uploadFileLoader
															? null
															: handleDeleteFile(attachment.id)
													}
												/>
											</div>
										</div>
									) : (
										<li
											key={index}
											className="block text-base font-inter text-secondary"
										>
											{attachment.name}
										</li>
									)
								)}
							</div>
						</div>
					</div>
					<Divider
						className="my-[64px]"
						color="!border-t-[4px] border-orange-500"
					/>
					<div className="flex justify-between mb-[64px]">
						<Button
							onClick={() => {
								let temp = readTabs;
								temp[4].isRead = true;
								handleValidate();
								setReadTabs(temp);
								setSection('section4');
							}}
							type="primary"
							isDisabled={savingIncident}
							className="font-bold mr-4 !rounded-3xl px-[40px] py-[16px]"
						>
							Back to Analysis
						</Button>
						{!isInvestigation ? (
							<Button
								onClick={() => {
									let temp = readTabs;
									temp[4].isRead = true;
									handleValidate();
									setReadTabs(temp);
									setSection('section7');
								}}
								type="primary"
								isDisabled={savingIncident}
								className="font-bold mr-4 !rounded-3xl px-[40px] py-[16px]"
							>
								Continue to Corrective Actions
							</Button>
						) : (
							<Button
								onClick={() => {
									let temp = readTabs;
									temp[4].isRead = true;
									handleValidate();
									setReadTabs(temp);
									setSection('section6');
								}}
								type="primary"
								isDisabled={savingIncident}
								className="font-bold mr-4 !rounded-3xl px-[40px] py-[16px]"
							>
								Continue to ICAM
							</Button>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default IncidentSection5;
