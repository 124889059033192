import {
	IOption,
	IQueryReportSection,
	IVehicleIncidentSection6,
} from '../../../types/VehicleIncidents';
import React, { useEffect, useState } from 'react';
import Heading from '../../atoms/Heading';
import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import DateInput from 'components/atoms/DateInput';
import Input from 'components/atoms/Input';
import TextareaInput from 'components/atoms/TextareaInput';
import ToggleButtonV2 from 'components/atoms/ToggleButtonV2';
import { useAppSelector } from '../../../redux/hooks';
import { disableWriteAccess } from '../../../utils/disableWriteAccess';
import {
	useGetIncidentSection6Mutation,
	useUpdateIncidentSection6Mutation,
} from '../../../redux/api/vehicleIncidents';
import { removeSeconds } from 'utils/removeSeconds';
import { PlusIcon, TrashIcon, ClockIcon } from '@heroicons/react/outline';
import { debounce } from 'lodash';
import LoaderSpinner from '../../atoms/LoaderSpinner';

interface IEvent {
	id: number;
	date: Date;
	time: string;
	eventDescription: string;
	isCritical: boolean;
}

interface ISection6InitialState {
	id: number | null;
	events: IEvent[];
	peepoPeopleTeamFactors: string;
	peepoEnvironment: string;
	peepoEquipment: string;
	peepoProcedures: string;
	peepoOrganisation: string;
	findingsCriticalControlFailures: string;
	findingsSignificantLearnings: string;
	findingsAdditionalFindings: string;
}

const initialState: ISection6InitialState = {
	id: null,
	events: [],
	peepoPeopleTeamFactors: '',
	peepoEnvironment: '',
	peepoEquipment: '',
	peepoProcedures: '',
	peepoOrganisation: '',
	findingsCriticalControlFailures: '',
	findingsSignificantLearnings: '',
	findingsAdditionalFindings: '',
};

export interface IIncidentSection6 {
	sectionId: number | null;
	setSection: (section: IQueryReportSection) => void;
	handleValidate: () => void;
	savingIncident: boolean;
	setSavingIncident: (input: boolean) => void;
	readTabs: { name: string; isRead: boolean }[];
	setReadTabs: (input: { name: string; isRead: boolean }[]) => void;
}

const IncidentSection6: React.FC<IIncidentSection6> = ({
	sectionId,
	setSection,
	handleValidate,
	savingIncident,
	setSavingIncident,
	readTabs,
	setReadTabs,
}) => {
	const [getIncidentSection6, { data, isSuccess, isLoading }] =
		useGetIncidentSection6Mutation();
	const [section6, setSection6] = useState(initialState); //TODO: uncomment when porting
	const [updateIncidentSection6, { isLoading: saving }] =
		useUpdateIncidentSection6Mutation(); //TODO: uncomment when porting
	const userPermission = useAppSelector((state) => state.user.permissions);
	const incidentBranchId = useAppSelector(
		(state) => state.vehicleIncident.incidentBranchId
	);
	const disableIncidentWriteAccess = disableWriteAccess(
		'incident_level',
		userPermission,
		incidentBranchId
	);

	useEffect(() => {
		if (saving) {
			setSavingIncident(true);
		} else {
			setSavingIncident(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saving]);

	useEffect(() => {
		if (sectionId !== null) {
			getIncidentSection6(sectionId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isSuccess && data) {
			const { events } = data.data;

			let formattedEvents: IEvent[] = [];

			if (events && events?.length) {
				formattedEvents = events.map((event: any) => {
					return {
						id: event.id,
						date: new Date(event.date),
						time: removeSeconds(event.time),
						eventDescription: event.eventDescription,
						isCritical: event.isCritical,
					};
				});
			}

			setSection6({
				id: data.data.id,
				peepoPeopleTeamFactors: data.data.peepoPeopleTeamFactors,
				peepoEnvironment: data.data.peepoEnvironment,
				peepoEquipment: data.data.peepoEquipment,
				peepoProcedures: data.data.peepoProcedures,
				peepoOrganisation: data.data.peepoOrganisation,
				findingsCriticalControlFailures:
					data.data.findingsCriticalControlFailures,
				findingsSignificantLearnings: data.data.findingsSignificantLearnings,
				findingsAdditionalFindings: data.data.findingsAdditionalFindings,
				events: formattedEvents,
			});
		}
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleOnBlur = async () => {
		if (disableIncidentWriteAccess) {
			return;
		}

		await updatePayload();
	};

	const handleSection6Update = (
		property: string,
		value: string | boolean | Date | IOption[],
		eventIndex?: number,
		disableUpdateRequest?: boolean
	) => {
		const newSection6 = section6;

		if (eventIndex !== undefined) {
			const newEvents = [...section6.events];
			newEvents[eventIndex] = {
				...newEvents[eventIndex],
				[property]: value,
			};

			newSection6.events = newEvents;
			setSection6(newSection6);
		} else {
			// @ts-ignore
			newSection6[property] = value;
			setSection6(newSection6);
		}

		if (!disableUpdateRequest) {
			handleOnBlur();
		}
	};

	const updatePayload = debounce(async (newEvents?: IEvent[]) => {
		const { events } = section6;

		const formattedEvents: IVehicleIncidentSection6['events'] = [];
		const tempActions = newEvents ? [...newEvents] : [...events];

		// do any additional formatting here
		tempActions.forEach((event) => {
			const formatTime =
				event.time && event.time.length < 6
					? `${event.time}:00.000`
					: event.time;
			formattedEvents.push({
				id: event.id,
				date: event.date,
				time: formatTime,
				eventDescription: event.eventDescription,
				isCritical: event.isCritical,
			});
		});

		const payload: IVehicleIncidentSection6 = {
			id: sectionId,
			events: formattedEvents,
			peepoPeopleTeamFactors: section6.peepoPeopleTeamFactors,
			peepoEnvironment: section6.peepoEnvironment,
			peepoEquipment: section6.peepoEquipment,
			peepoProcedures: section6.peepoProcedures,
			peepoOrganisation: section6.peepoOrganisation,
			findingsCriticalControlFailures: section6.findingsCriticalControlFailures,
			findingsSignificantLearnings: section6.findingsSignificantLearnings,
			findingsAdditionalFindings: section6.findingsAdditionalFindings,
		};

		await updateIncidentSection6(payload);
	}, 500);

	const deleteEvent = async (eventId: number) => {
		const newEvents = section6.events.filter((event) => event.id !== eventId);
		setSection6({
			...section6,
			events: newEvents,
		});

		await updatePayload(newEvents);
	};

	const addEvent = async () => {
		if (disableIncidentWriteAccess) return;
		const { events } = section6;
		let assignId = 0;
		if (events && events.length > 0) {
			assignId = events[events.length - 1].id + 1;
		} else {
			assignId += 1;
		}

		const addEvents = [
			...section6.events,
			{
				id: assignId,
				date: new Date(),
				time: '',
				isCritical: false,
				eventDescription: '',
			},
		];

		setSection6((section7) => {
			return {
				...section7,
				events: addEvents,
			};
		});

		await updatePayload(addEvents);
	};

	return (
		<>
			{isLoading || !data ? (
				<LoaderSpinner />
			) : (
				<div>
					<Heading type="h1" className="uppercase mb-[64px] mt-[20px]">
						6. ICAM
					</Heading>
					<Heading type="h2" className="uppercase tracking-[2.08px]">
						Timeline of Events
					</Heading>
					{section6.events.map((event, index) => (
						<div className="mt-6 !mb-12 space-y-8 py-10 px-[40px] border-solid border-[1px] bg-[#FEEDD6] rounded-md">
							<div className="flex items-center justify-between space-x-4">
								<Heading type="h2" className="uppercase">
									Event {index + 1}
								</Heading>
								<Button
									type="quinary"
									className={`font-bold !rounded-3xl px-[24px] py-[10px] tracking-[1.92px] uppercase ${'font-bold'}`}
									onClick={() => deleteEvent(event.id)}
									size="md"
								>
									<TrashIcon
										height={20}
										width={18}
										className="cursor-pointer"
									/>
									&nbsp;Delete
								</Button>
							</div>
							<div className="flex flex-row gap-8">
								<div className="w-72">
									<DateInput
										placeholder="Select Date"
										onChange={(value: Date) =>
											handleSection6Update('date', value, index)
										}
										className="w-72"
										label={`Date`}
										iconClassName="text-black"
										selected={event.date}
										wrapperClassName="react-datepicker-margin-0"
										isDisabled={disableIncidentWriteAccess}
									/>
								</div>
								<div className="w-72">
									<Input
										type="time"
										onChange={(value: string) =>
											handleSection6Update('time', value, index)
										}
										onBlur={handleOnBlur}
										value={event.time}
										className=""
										label="Incident Time"
										Icon={ClockIcon}
										iconClassName="text-black"
										iconPosition="trailing"
										placeholder="Select Time"
										isDisabled={disableIncidentWriteAccess}
									/>
								</div>
							</div>
							<TextareaInput
								className="w-full"
								value={event.eventDescription}
								rows={10}
								label={`Event`}
								placeholder=""
								resize={true}
								onChange={(value: string) =>
									handleSection6Update('eventDescription', value, index)
								}
								onBlur={handleOnBlur}
								isDisabled={disableIncidentWriteAccess}
							/>
							<ToggleButtonV2
								toggle={event.isCritical}
								onToggle={(value: boolean) => {
									handleSection6Update('isCritical', value, index);
								}}
								label={`Critical Event`}
								isDisabled={false}
							/>
						</div>
					))}
					<Button
						onClick={addEvent}
						type="secondary"
						className="font-bold mr-4 mt-[32px] !rounded-3xl px-[24px] py-[10px] tracking-[1.92px]"
						isDisabled={disableIncidentWriteAccess}
					>
						Add Event
						<PlusIcon height={18} width={18} className="ml-2" />
					</Button>
					<Divider
						className="my-[64px]"
						color="!border-t-[4px] border-orange-500"
					/>
					<Heading type="h2" className="uppercase tracking-[2.08px] mb-8">
						PEEPO
					</Heading>
					<TextareaInput
						className="w-full"
						value={section6.peepoPeopleTeamFactors}
						rows={10}
						label={`People - individual/team factors`}
						placeholder=""
						resize={true}
						onChange={(value: string) =>
							handleSection6Update('peepoPeopleTeamFactors', value)
						}
						onBlur={handleOnBlur}
						isDisabled={disableIncidentWriteAccess}
					/>
					<TextareaInput
						className="w-full"
						value={section6.peepoEnvironment}
						rows={10}
						label={`Environment`}
						placeholder=""
						resize={true}
						onChange={(value: string) =>
							handleSection6Update('peepoEnvironment', value)
						}
						onBlur={handleOnBlur}
						isDisabled={disableIncidentWriteAccess}
					/>
					<TextareaInput
						className="w-full"
						value={section6.peepoEquipment}
						rows={10}
						label={`Equipment`}
						placeholder=""
						resize={true}
						onChange={(value: string) =>
							handleSection6Update('peepoEquipment', value)
						}
						onBlur={handleOnBlur}
						isDisabled={disableIncidentWriteAccess}
					/>
					<TextareaInput
						className="w-full"
						value={section6.peepoProcedures}
						rows={10}
						label={`Procedures`}
						placeholder=""
						resize={true}
						onChange={(value: string) =>
							handleSection6Update('peepoProcedures', value)
						}
						onBlur={handleOnBlur}
						isDisabled={disableIncidentWriteAccess}
					/>
					<TextareaInput
						className="w-full"
						value={section6.peepoOrganisation}
						rows={10}
						label={`Organisation`}
						placeholder=""
						resize={true}
						onChange={(value: string) =>
							handleSection6Update('peepoOrganisation', value)
						}
						onBlur={handleOnBlur}
						isDisabled={disableIncidentWriteAccess}
					/>

					<Divider
						className="my-[64px]"
						color="!border-t-[4px] border-orange-500"
					/>
					<Heading type="h2" className="uppercase tracking-[2.08px] mb-8">
						FINDINGS &amp; RECOMMENDATIONS
					</Heading>
					<TextareaInput
						className="w-full"
						value={section6.findingsCriticalControlFailures}
						rows={10}
						label={`Critical control failures`}
						placeholder=""
						resize={true}
						onChange={(value: string) =>
							handleSection6Update('findingsCriticalControlFailures', value)
						}
						onBlur={handleOnBlur}
						isDisabled={disableIncidentWriteAccess}
					/>
					<TextareaInput
						className="w-full"
						value={section6.findingsSignificantLearnings}
						rows={10}
						label={`Significant learnings`}
						placeholder=""
						resize={true}
						onChange={(value: string) =>
							handleSection6Update('findingsSignificantLearnings', value)
						}
						onBlur={handleOnBlur}
						isDisabled={disableIncidentWriteAccess}
					/>
					<TextareaInput
						className="w-full"
						value={section6.findingsAdditionalFindings}
						rows={10}
						label={`Additional findings`}
						placeholder=""
						resize={true}
						onChange={(value: string) =>
							handleSection6Update('findingsAdditionalFindings', value)
						}
						onBlur={handleOnBlur}
						isDisabled={disableIncidentWriteAccess}
					/>

					<Divider
						className="my-[64px]"
						color="!border-t-[4px] border-orange-500"
					/>
					<div className="flex justify-between mb-[64px]">
						<Button
							onClick={() => {
								let temp = readTabs;
								temp[5].isRead = true;
								handleValidate();
								setReadTabs(temp);
								setSection('section5');
							}}
							type="primary"
							className="font-bold mr-4 !rounded-3xl px-[40px] py-[16px] tracking-[1.92px]"
							isDisabled={savingIncident}
						>
							Back to Investigation
						</Button>
						<Button
							onClick={() => {
								let temp = readTabs;
								temp[5].isRead = true;
								handleValidate();
								setReadTabs(temp);
								setSection('section7');
							}}
							type="primary"
							className="font-bold mr-4 !rounded-3xl px-[40px] py-[16px]"
							isDisabled={savingIncident}
						>
							Continue to Corrective Actions
						</Button>
					</div>
				</div>
			)}
		</>
	);
};

export default IncidentSection6;
